
import {useRoute, useRouter} from 'vue-router';
import {defineComponent, onBeforeMount, onMounted, reactive, toRefs} from 'vue';
import {ErrorMessage, Field, Form} from 'vee-validate';
// import logoApplication from '@/components/LogoApplication.vue';
import identity from '@/api/identity';

interface User {
    id: string;
    tpId: string;
    username: string;
    displayName: string;
}

export default defineComponent({
    name: 'LoginBlanco',
    components: {
        Form,
        Field,
        ErrorMessage,
        // logoApplication
    },
    setup ()
    {
        const route = useRoute();
        const router = useRouter();
        const applicationId = route.params.id.toString();
        const redirectUrl = route.query.redirectUrl as string || undefined;

        const data = reactive({
            application: {},
            user: {} as User,
            showInput: false,
            username: '',
            loading: true,
            loadingLogin: false,
            showCookiesMessage: true,
            error: 0,
            weHaveUserInfo: false,
            useUserInfo: false,
            // below this is for testing
            doit: true,
        });

        function toggleShowInput (): void
        {
            if (data.showInput)
            {
                if (data.user.username)
                {
                    data.username = data.user.username;
                }
                data.showInput = false;
            }
            else
            {
                data.showInput = true;
                data.username = '';
            }
        }

        function hideUser (): void
        {
            data.useUserInfo = false;
        }

        function useUser (): void
        {
            data.useUserInfo = true;
            data.username = data.user.username;
        }

        function toggleDoIt (): void
        {
            data.doit = !data.doit;
        }

        async function login (): Promise<void>
        {
            if (!data.loadingLogin)
            {
                try
                {
                    data.error = 0;
                    data.loadingLogin = true;
                    await identity.login(data.username.trim(), applicationId, redirectUrl ? decodeURI(redirectUrl) : undefined);
                    window.location.href = `${process.env.VUE_APP_IDENTITY_URL}redirect/login/${applicationId}`;
                }
                catch (error)
                {
                    data.error = error.response.status;
                    data.loadingLogin = false;
                }
            }
        }

        function logoutUrl (): string
        {
            return `${process.env.VUE_APP_IDENTITY_URL}logout?applicationId=${applicationId}`;
        }

        function saveCookieNotification () : void
        {
            data.showCookiesMessage = false;
            localStorage.setItem('cookieNotification', 'false');
        }

        function checkLocalStorageForCookieNotification () :boolean
        {
            const data = localStorage.getItem('cookieNotification');

            return data !== 'false';

        }

        onMounted(async () =>
        {

            try
            {
                data.application = (await identity.getApplication(applicationId)).data;
            }
            catch (e)
            {
                await router.push({name : 'Error', params : {code : '404', message : 'Page not found.'}});
                data.loading = false;
                return;
            }
            try
            {
                await identity.getUser(applicationId);
                const redirectQS = redirectUrl ? `?redirectUrl=${redirectUrl}` : '';
                // const redirectQS = '?redirectUrl=http%3A%2F%2Flocalhost%3A8888%2Fadmin';
                // alert(`${process.env.VUE_APP_IDENTITY_URL}redirect/application/${applicationId}${redirectQS}`);
                window.location.href = `${process.env.VUE_APP_IDENTITY_URL}redirect/application/${applicationId}${redirectQS}`;
            }
            catch (e)
            {
                data.loading = false;
                if (e.response.data.username)
                {
                    // delete e.response.data.photo;
                    data.user = e.response.data;
                    data.username = data.user.username;
                    data.weHaveUserInfo = true;
                    data.useUserInfo = true;
                }
                else data.showInput = true;
            }
        });

        onBeforeMount( () =>
        {
            data.showCookiesMessage = checkLocalStorageForCookieNotification();
        });

        return {...toRefs(data), toggleShowInput, hideUser, useUser, toggleDoIt, login, logoutUrl, saveCookieNotification};
    }
});
